export default {
    name: 'tangorx',
    fullName: 'tangorx',
    url: 'https://www.zetacorporation.com',
    author: {
        name: 'Meridian',
        profile_url: 'https://www.meridiantdigital.com',
        twitter: 'https://twitter.com/ZetaCoPK',
        instagram: 'https://www.instagram.com/tangorxthestore/',
        linkedin: 'https://www.linkedin.com/in/zetacorporationpk/',
        facebook: 'https://www.facebook.com/tangorxTheStore'
    },
    contacts: {
        address: '13, Hyderabad Colony, Jail Road, Karachi.',
        email: 'zetacorporation@mbsattar.com',
        phone: '0333-3803004',
        timing: '9:45 am - 06:30 pm',
    },
};
