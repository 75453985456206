export default [
    { image: 'images/brands/1.png' },
    { image: 'images/brands/2.png' },
    { image: 'images/brands/3.png' },
    { image: 'images/brands/4.png' },
    { image: 'images/brands/5.png' },
    { image: 'images/brands/6.png' },
    { image: 'images/brands/7.png' },
    { image: 'images/brands/8.png' },
    { image: 'images/brands/9.png' },
    { image: 'images/brands/10.png' },
    { image: 'images/brands/11.png' },
    { image: 'images/brands/12.png' },
    { image: 'images/brands/13.png' },
];
