// react
import React, { useMemo, useEffect, useReducer, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

// application
import shopApi from '../../api/shop';
import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import { connect } from 'react-redux';
import { cartUpdateDeliveryCharges } from '../../store/cart';
import { useLocation } from 'react-router-dom';

// var categories = categoriesStatic
function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === 'string') {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === 'string') {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === 'string') {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [
        parseQueryOptions(location),
        parseQueryFilters(location),
    ];
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    /**
     * Indicates that the products list is loading.
     */
    popCategoriesListIsLoading: true,
    mainCategoriesListIsLoading: true,
    /**
     * Products list.
     */
    popCategoriesList: [],
    mainCategoriesList: [],
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},

};

function reducer(state, action) {

    console.log(action)

    switch (action.type) {
        case 'FETCH_POP_CATEGORY_LIST':
            return { ...state, popCategoriesListIsLoading: true };
        case 'FETCH_POP_CATEGORY_LIST_SUCCESS':
            return { ...state, popCategoriesListIsLoading: false, popCategoriesList: action.categoryList };

        case 'FETCH_MAIN_CATEGORY_LIST':
            return { ...state, mainCategoriesListIsLoading: true };
        case 'FETCH_MAIN_CATEGORY_LIST_SUCCESS':
            return { ...state, mainCategoriesListIsLoading: false, mainCategoriesList: action.categoryList };
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function CategoryPage(props) {


    const [state, dispatch] = useReducer(reducer, initialState, init);

    useEffect(() => {
        let canceled = false;

        dispatch({ type: 'FETCH_POP_CATEGORY_LIST' });

        console.log("props.categorySlug")
        console.log(props.categorySlug)

        shopApi.getCategoryBySlug(props.categorySlug, { depth: 2 })
            .then((categoryList) => {
                console.log("categoryList here here")
                console.log(categoryList)
                dispatch({ type: 'FETCH_POP_CATEGORY_LIST_SUCCESS', categoryList });
            });

        return () => {
            canceled = true;
        };
    }, [dispatch, props.categorySlug, state.options, state.filters]);


    useEffect(() => {
        let canceled = false;

        dispatch({ type: 'FETCH_MAIN_CATEGORY_LIST' });

        shopApi.getCategories({ depth: 0 })
            .then((categoryList) => {

                dispatch({ type: 'FETCH_MAIN_CATEGORY_LIST_SUCCESS', categoryList });
            });

        return () => {
            canceled = true;
        };
    }, [dispatch, state.options, state.filters]);


    return (
        <React.Fragment>
            <Helmet>
                <title>{`tangorx`}</title>
            </Helmet>

            {props.categorySlug !== 'all' ?

                < BlockCategories
                    layout="classic"
                    categories={state.popCategoriesList}
                    gotoPage="catalog"
                    loading={state.popCategoriesListIsLoading}
                    dispatch={dispatch}
                    title={props.categorySlug.split("-").join(" ")}

                />
                :
                < BlockCategories
                    layout="classic"
                    categories={state.mainCategoriesList}
                    gotoPage="category"
                    loading={state.mainCategoriesListIsLoading}
                    dispatch={dispatch}
                    title={props.categorySlug.split("-").join(" ")}

                />
            }

        </React.Fragment>
    );
}


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartUpdateDeliveryCharges
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
