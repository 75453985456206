// react
import React, { useMemo, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks

import BlockBrands from '../blocks/BlockBrands';
import BlockBanner from '../blocks/BlockBanner';
import BlockCategories from '../blocks/BlockCategories';
import BlockMainCategories from '../blocks/BlockMainCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import VideoPlayer from '../widgets/VideoPlayer'
// data stubs
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import { connect } from 'react-redux';
import { cartUpdateDeliveryCharges } from '../../store/cart';
import BlockHeader from '../shared/BlockHeader';
import BlockSlideShow from '../blocks/BlockSlideShow';


function HomePageOne(props) {

    useEffect(() => {
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_SETTING + "Delivery Charges Amount" }).then(resD => {
            apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_SETTING + "Free Delivery Amount" }).then(resF => {
                props.cartUpdateDeliveryCharges({ delivery: resD.data, freeDeliveryAmount: resF.data })
            });
        });
        return () => console.log('unmounting...');
    }, [])


    /**
    * Door Hardware.
    */
    const perfumesProducts = useProductTabs(
        useMemo(() =>
            []),
        (tab) => shopApi.getPopularProducts({ limit: 12, category: "Local" }),
    );

    /**
    * Furniture  Hardware.
    */
    const petFoodProducts = useProductTabs(
        useMemo(() =>
            []),
        (tab) => shopApi.getPopularProducts({ limit: 12, category: "Imported" }),
    );


    /**
* Glass  Hardware.
*/
   
    /**
* Fencing & Netting
*/
    // const fencingNetting = useProductTabs(
    //     useMemo(() =>
    //         []),
    //     (tab) => shopApi.getPopularProducts({ limit: 12, category: "other-hardware" }),
    // );


    const popCategories = useDeferredData(() => (
        shopApi.getPopularCategories({ depth: 0 })
    ), []);

    console.log("popCategories")
    console.log(popCategories)
    /**
     * Product columns.
     */
    const columns = useProductColumns(
        useMemo(() => [
            {
                title: 'Top Rated',
                source: () => shopApi.getTopRatedProducts({ limit: 3 }),
            },
            {
                title: 'Special Offers',
                source: () => shopApi.getDiscountedProducts({ limit: 3 }),
            },
            {
                title: 'Bestsellers',
                source: () => shopApi.getBestSellerProducts({ limit: 3 }),
            },
        ], []),
    );

    return (
        <React.Fragment>

            <Helmet>
                <title>{`tangorx`}</title>
            </Helmet>

            {useMemo(() => <BlockSlideShow />, [])}

            {/* <BlockHeader title="Door Hardware" /> */}

            {useMemo(() => (
                <BlockProductsCarousel
                    title="Local"
                    layout="grid-5"
                    products={perfumesProducts.data}
                    loading={perfumesProducts.isLoading}
                    groups={perfumesProducts.tabs}
                    onGroupClick={perfumesProducts.handleTabChange}
                />
            ), [perfumesProducts])}

            {/* <BlockHeader title="Furniture Hardware" /> */}


            {useMemo(() => (
                <BlockProductsCarousel
                    title="Imported"
                    layout="grid-5"
                    products={petFoodProducts.data}
                    loading={petFoodProducts.isLoading}
                    groups={petFoodProducts.tabs}
                    onGroupClick={petFoodProducts.handleTabChange}
                />
            ), [petFoodProducts])}

            {/* <BlockHeader title="Glass Hardware" /> */}


          


            {/* <BlockHeader title="Other Hardware" /> */}

            {useMemo(() => <BlockBanner imgSrc='url("images/banners/banner-1.png")' imgSrcMobile='url("images/banners/banner-1-mobile.png")' />, [])}

            {useMemo(() => <BlockBrands />, [])}

            {useMemo(() => <BlockProductColumns columns={columns} />, [columns])}

            {useMemo(() => <BlockBanner imgSrc='url("images/banners/banner-3.png")' imgSrcMobile='url("images/banners/banner-3-mobile.png")' />, [])}

            {useMemo(() => (
                <BlockCategories
                    title="Popular Categories"
                    layout="compact"
                    categories={popCategories.data}
                    loading={popCategories.isLoading}
                />
            ), [popCategories])}

        </React.Fragment>
    );
}


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartUpdateDeliveryCharges
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageOne);
