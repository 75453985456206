// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg, Wishlist16SvgRed } from '../../svg';
import { wishlistAddItem, wishlistRemoveItem } from '../../store/wishlist';
import { toast } from 'react-toastify';

import parse from 'html-react-parser'
// import createDOMPurify from 'dompurify'
// import { JSDOM } from 'jsdom'

// const window = (new JSDOM('')).window
// const DOMPurify = createDOMPurify(window)

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            variants: [],
            varSelected: false,
            isVariant: false
        };

        if (props.product.variants.length !== 0) {

            setTimeout(() => {
                // this.getSizeColor(props.product.variants[0].size_id)

                if (document.getElementsByName("size")[0] != undefined) {
                    document.getElementsByName("size")[0].click()
                }
                if (document.getElementsByName("color")[0] != undefined) {
                    document.getElementsByName("color")[0].click()
                }


            }, 100);

        }

        // for (let i = 0; i < props.wishlist.length; i++) {


        // }
        props.wishlist.forEach(element => {
            if (element.id == props.product.id) {
                props.product.is_wish_list = true
            }
        });

        console.log("props.wishlist");
        console.log(props.wishlist);
        console.log(props.product);

    }

    componentDidMount() {
       this.setState({ isVariant: this.props.product.variants.length !== 0 })
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    getSizeColor(size) {
        // console.log("size")
        // console.log(size)

        const {
            product,
        } = this.props;

        var variant = product.variants
        var some = []
        for (let i = 0; i < variant.length; i++) {
            if (variant[i].size == size) {
                some.push(variant[i])
                product.price = variant[i].price
            }
        }

        // console.log(product)

        this.setState({ variants: some })

        // console.log(product.selectedVariant)

        if (document.getElementsByName("color")[0] != undefined) {
            document.getElementsByName("color")[0].click()
        }
    }


    selectVarColor(variant) {
        const {
            product,
        } = this.props;

        // console.log(variant)
        console.log(variant.image)

        product.images = []
        product.images.push(variant.image)

        this.setState({ varSelected: true })

        product.selectedVariant = variant
        product.price = variant.price
    }

    render() {
       
        const {
            product,
            layout,
            wishlistAddItem,
            compareAddItem,
            cartAddItem,
            wishlistRemoveItem,
            wishlist
        } = this.props;
        const { quantity, variants } = this.state;

        let prices;

        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price"><Currency value={product.price} /></span>
                    {' '}
                    <span className="product__old-price"><Currency value={product.compareAtPrice} /></span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;
        }



        const variantsSize = [...new Set(product.variants.reduce((a, c) => [...a, c.size], []))];

        const variantsName = variantsSize.map((res) =>
            <label onClick={() => this.getSizeColor(res)}>
                <input type="radio" name="size" />
                <span>{res}</span>
            </label>
        )

        const variantsColor = variants.map((res) =>
            <label onClick={() => this.selectVarColor(res)}>
                <input type="radio" name="color" />
                <span>{res.color}</span>
            </label>
        )


        const addItem = () => {

            // console.log(quantity)
            // console.log(product.selectedVariant.quantity)

            // if (quantity > product.selectedVariant.quantity) {

            //     toast.error(`Quantity must not exceed available quantity`);

            //     return new Promise((resolve) => {
            //         resolve();
            //     })
            // }

            if (this.state.isVariant) {
                console.log(this.state.isVariant);
                if (this.state.varSelected) {
                    product.selectedVariant.productId = product.id;
                    product.selectedVariant.name = product.name;
                    product.selectedVariant.slug = product.slug;
                    console.log(product);
                    return cartAddItem(product.selectedVariant, [], quantity)
                } else {
                    toast.error(`Select product size and color`);

                    return new Promise((resolve) => {
                        resolve();
                    })
                }
            } else {
                // product.productId = product.id;
                return cartAddItem(product, [], quantity)
            }
        }

        const addToWishlist = () => {


            if (this.state.isVariant) {
                if (this.state.varSelected) {
                    product.selectedVariant.productId = product.id;

                    if (!product.is_wish_list) {
                        product.is_wish_list = true
                        return wishlistAddItem(product)
                    } else {
                        product.is_wish_list = false
                        return wishlistRemoveItem(product.id)
                    }
                }
                else {
                    toast.error(`Select product size and color`);

                    return new Promise((resolve) => {
                        resolve();
                    })
                }
            }
            else {
                console.log("Here here")
                console.log(product)
                if (!product.is_wish_list) {
                    product.is_wish_list = true
                    return wishlistAddItem(product)
                } else {
                    product.is_wish_list = false
                    return wishlistRemoveItem(product.id)
                }
            }
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                   
                    <ProductGallery layout={layout} images={product.images} />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => addToWishlist(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        {
                                            product.is_wish_list ?
                                                <Wishlist16SvgRed />
                                                :
                                                <Wishlist16Svg />
                                        }
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h1 className="product__name">{product.name}</h1>
                        <div className="product__rating">
                        </div>
                        <div className="product__description" dangerouslySetInnerHTML={{__html:product.description}}>
                     
                        </div>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                Availability :  &nbsp;
                                {' '}
                                {product.quantity == "0" ?
                                    <span className="text-error">Out Of Stock</span>
                                    :
                                    <span className="text-success">In Stock</span>
                                }
                            </li>
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability:
                            {' '}
                            {product.quantity == "0" ?
                                <span className="text-error">Out Of Stock</span>
                                :
                                <span className="text-success">In Stock</span>
                            }
                        </div>

                        <div className="product__prices">
                            {prices}
                        </div>

                        <form className="product__options">


                            {
                                this.state.isVariant ?
                                    <div>
                                        <div className="form-group product__option">
                                            <div className="product__option-label">Weight</div>
                                            <div className="input-radio-label">
                                                <div className="input-radio-label__list">

                                                    {variantsName}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group product__option" style={{display:'none'}}>
                                            <div className="product__option-label">Color</div>
                                            <div className="input-radio-label">
                                                <div className="input-radio-label__list">

                                                    {variantsColor}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            < div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => addItem()}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={product.quantity == "0"}
                                                    className={classNames('btn btn-primary btn-lg text-color-white', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    Add to cart
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => addToWishlist(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    {
                                                        product.is_wish_list ?
                                                            <Wishlist16SvgRed />
                                                            :
                                                            <Wishlist16Svg />
                                                    }
                                                </button>
                                            )}
                                        />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">

                    </div>
                </div>
            </div >
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    wishlistRemoveItem
};

// export default connect(
//     () => ({}),
//     mapDispatchToProps,
// )(Product);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product);